import { Typography } from 'Typography';
import { Image } from 'Image';
import { parseHtml } from 'FrontRoyalAngular/html.utils';
import { Container } from 'Container';
import { type DescriptionSectionProps } from './DescriptionSection.types';

export function DescriptionSection({ content, image }: DescriptionSectionProps) {
    return (
        <section className="bg-white pt-6 md:pb-10 md:pt-[5.25rem]">
            <Container>
                <div className="mx-auto max-w-[555px] space-y-8 md:max-w-full md:space-y-13.5">
                    <div className="flex flex-col-reverse md:grid md:grid-cols-12 md:gap-7.5">
                        <div className="space-y-7.5 md:col-span-6">
                            {content.map(item => (
                                <div key={item.id} className="space-y-2.5">
                                    {item.title && (
                                        <Typography variant="header2" className="text-grey-darker">
                                            {item.title}
                                        </Typography>
                                    )}
                                    {item.description && (
                                        <Typography
                                            variant="body2"
                                            className="text-xs leading-[28px] text-beige-beyond-beyond-dark md:text-[16px] md:leading-[30px]"
                                        >
                                            {parseHtml(item.description)}
                                        </Typography>
                                    )}
                                    {item.list && (
                                        <ul className="-mt-[6px] list-outside list-disc leading-[28px] md:mt-0 md:leading-[30px] ltr:pl-6 md:ltr:pl-7 rtl:pr-7">
                                            {item.list.map(listItem => (
                                                <li
                                                    key={listItem.item}
                                                    className="text-xs text-beige-beyond-beyond-dark md:text-[16px] ltr:pl-2.5 rtl:pr-3"
                                                >
                                                    {parseHtml(listItem.item)}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="-mt-2 block pb-5 md:col-span-6 md:mt-0 md:pt-0 lg:col-span-5 lg:col-start-8">
                            <div className="flex items-center justify-center md:-mt-1 md:justify-end">
                                <Image {...image} />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
}
