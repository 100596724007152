import { Container } from 'Container';
import { SlopeBackground } from 'Resources/SlopeBackground';
import { Typography } from 'Typography';
import { FormCard } from 'Nominations/FormCard';
import { type FormSectionProps } from './FormSection.types';

export function FormSection({ nominationState, title, formCard }: FormSectionProps) {
    return (
        <section className="relative overflow-hidden pb-16 pt-9 md:pb-4 md:pt-[7.25rem]">
            {nominationState === 'preNomination' && (
                <>
                    <SlopeBackground />
                    <Container className="relative">
                        <Typography
                            variant="header2"
                            className="pt-[3.75rem] uppercase text-beige-beyond-beyond-dark xs:pt-24 md:pt-12 lg:pt-0"
                        >
                            {title}
                        </Typography>
                        <FormCard {...formCard} />
                    </Container>
                </>
            )}
        </section>
    );
}
