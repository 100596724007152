import { useAppHeaderViewModel } from 'Navigation';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Footer } from 'Resources/Footer';

export function Layout() {
    const AppHeaderViewModel = useAppHeaderViewModel();
    const { t } = useTranslation();

    AppHeaderViewModel.setBodyBackground('beige');
    AppHeaderViewModel.showAlternateHomeButton = false;
    AppHeaderViewModel.setTitleHTML(t('nominations.nominations.nominations_title').toLocaleUpperCase());

    return (
        <div className="flex animate-fade-in flex-col sm:min-h-[calc(100vh-75px)]">
            <div className="relative flex-grow">
                <Outlet />
            </div>
            <Footer className="flex-shrink-0" />
        </div>
    );
}
